<template>
  <div class="demand">
    <Breadcrumb :data="breData" />
    <div class="container">
      <Title :title="'发布需求'" :des="'让服务商来找你'" />
      <ReleaseDemand />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import ReleaseDemand from '@/components/ReleaseDemand'
import Title from '@/components/Title'
// const i18nPrefix = {
//   label: 'account.register.releaseForm.lable.',
//   pla: 'account.register.releaseForm.pla.',
// }
export default {
  name: 'DemandRelease',
  components: {
    ReleaseDemand,
    Breadcrumb,
    Title,
  },
  data() {
    return {
      breData: [
        {
          name: '法律需求',
          path: '/service/demand',
        },
        {
          name: '发布需求',
          path: '/service/demand/release',
        },
      ],
    }
  },
  mounted() {},
  created() {},
  methods: {},
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.demand {
  width: @uni-width;
  margin: 0 auto;
  .container {
    width: 100%;
    background: #fff;
  }
}
</style>
